var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-n10"},[_c('v-col',{staticClass:"text-body-2 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3 ps-9 px-sm-16 font-weight-medium mt-10 mt-sm-0",class:"text-body-1 font-weight-bold",attrs:{"cols":"8","md":"6","lg":"6","xl":"6","sm":"7","offset-md":"1","align-self":_vm.$vuetify.breakpoint.smAndUp ? 'center' : ''}},[_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" Download our Mobile App Now.")]),_c('v-row',{staticClass:"mt-7"},[_c('v-img',{attrs:{"src":require("../assets/logo.svg"),"height":_vm.$vuetify.breakpoint.xl
                        ? 75
                        : _vm.$vuetify.breakpoint.lg
                            ? 55
                            : _vm.$vuetify.breakpoint.smAndUp
                                ? 45
                                : 40,"contain":""}})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"justify":"center","justify-sm":"start","justify-lg":"end"}},[_c('ApplestoreButton')],1)],1),_c('v-col',{staticClass:"ms-sm-14 ms-md-10 mt-n2 mt-sm-0",attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"justify":"center","justify-sm":"start"}},[_c('PlaystoreButton')],1)],1)],1)],1),_c('v-col',{staticClass:"pe-n16 mt-16 mt-sm-0",attrs:{"cols":"4","sm":"5","md":"5","lg":"5"}},[_c('v-img',{staticClass:"mt-16 mt-sm-10",attrs:{"src":require("../assets/thank-you.png"),"height":_vm.$vuetify.breakpoint.xl
                    ? 880
                    : _vm.$vuetify.breakpoint.smAndUp
                        ? 706
                        : '',"contain":"","position":"right"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }