var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-n10"},[_c('v-col',{staticClass:"\n        text-body-2 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3\n        ps-9\n        px-sm-16\n        font-weight-medium\n        mt-10 mt-sm-0\n      ",class:"text-body-1 font-weight-bold",attrs:{"cols":"8","sm":"6","md":"6","lg":"6","xl":"6","offset-sm":"1","align-self":_vm.$vuetify.breakpoint.smAndUp ? 'center' : ''}},[(!_vm.loading)?_c('div',[(_vm.isPaymentSuccess)?_c('v-row',{staticClass:"mt-12 mt-sm-7 mt-lg-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":require("../assets/payment-success.svg"),"height":_vm.$vuetify.breakpoint.xl
                  ? 300
                  : _vm.$vuetify.breakpoint.lg
                  ? 200
                  : _vm.$vuetify.breakpoint.smAndUp
                  ? 120
                  : 80,"contain":"","position":"center"}}),_c('v-row',{staticClass:"success--text",attrs:{"justify":"center"}},[_vm._v("Payment Successful")]),_c('v-row',{staticClass:"mt-2 mt-md-4",attrs:{"justify":"center"}},[_vm._v(" Welcome to"),_c('span',{staticClass:"primary--text mx-2"},[_vm._v(" Planet-Zero")]),_vm._v(" family. ")])],1)],1):_c('v-row',{staticClass:"mt-12 mt-sm-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":require("../assets/payment-failed.png"),"height":_vm.$vuetify.breakpoint.xl
                  ? 300
                  : _vm.$vuetify.breakpoint.lg
                  ? 200
                  : _vm.$vuetify.breakpoint.smAndUp
                  ? 120
                  : 80,"contain":"","position":"center"}}),_c('v-row',{staticClass:"red--text",attrs:{"justify":"center"}},[_vm._v(" Payment Failed ")]),_c('v-row',{staticClass:"\n                mt-md-4\n                text-caption text-sm-body-1 text-md-h5 text-lg-h4 text-xl-h3\n              ",attrs:{"justify":"center"}},[_vm._v(" Please contact out customer support. ")])],1)],1)],1):_c('div',[_c('v-row',{staticClass:"mt-7"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)]),_c('v-col',{staticClass:"pe-n16 mt-16 mt-sm-0",attrs:{"cols":"4","sm":"5","md":"5","lg":"5","xl":"5"}},[_c('v-img',{staticClass:"mt-16 mt-sm-10",attrs:{"src":"","height":_vm.$vuetify.breakpoint.xl
            ? 880
            : _vm.$vuetify.breakpoint.smAndUp
            ? 706
            : '',"contain":"","position":"right"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }