<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row class="mt-n10">
            <v-col cols="8" md="6" lg="6" xl="6" sm="7" offset-md="1"
                class="text-body-2 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3 ps-9 px-sm-16 font-weight-medium mt-10 mt-sm-0"
                :class="`text-body-1 font-weight-bold`" :align-self="$vuetify.breakpoint.smAndUp ? 'center' : ''">
                <v-row justify="center"> Download our Mobile App Now.</v-row>
                <v-row class="mt-7">
                    <v-img src="../assets/logo.svg" :height="
                        $vuetify.breakpoint.xl
                            ? 75
                            : $vuetify.breakpoint.lg
                                ? 55
                                : $vuetify.breakpoint.smAndUp
                                    ? 45
                                    : 40
                    " contain />
                </v-row>
                <v-row class="mt-7" justify="center">
                    <v-col cols="12" sm="5">
                        <v-row justify="center" justify-sm="start" justify-lg="end">
                            <ApplestoreButton />
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="5" class="ms-sm-14 ms-md-10 mt-n2 mt-sm-0">
                        <v-row justify="center" justify-sm="start">
                            <PlaystoreButton />
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" sm="5" md="5" lg="5" class="pe-n16 mt-16 mt-sm-0">
                <v-img class="mt-16 mt-sm-10" src="../assets/thank-you.png" :height="
                    $vuetify.breakpoint.xl
                        ? 880
                        : $vuetify.breakpoint.smAndUp
                            ? 706
                            : ''
                " contain position="right" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ApplestoreButton from "../components/ApplestoreButton";
import PlaystoreButton from "../components/PlaystoreButton";
export default {
    components: {
        ApplestoreButton,
        PlaystoreButton
    },

    created() {
        // var url = new URL(window.location.href);
        let os = this.getMobileOperatingSystem()
        if (os == 'Android') {
            window.open('https://play.google.com/store/apps/details?id=com.cocopalms.planetzero', '_blank')
        }
        else if (os == 'iOS') {
            window.open('https://apps.apple.com/us/app/planet-zero/id1601236749', '_blank')
        }


    },
    methods: {
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "unknown";
        }
    },
    data() {
        return {
            loading: true,
            isPaymentSuccess: false
        };
    }
};
</script>
  